<template>
  <div id="bg-img" :style="`background-image: url(${background});`">
    <TopNav/>
    <div :style="[isMobile ? 'padding-top: 6rem;' : 'padding-top: 0;']"></div>
    <router-view/>
    <BottomFooter/>
  </div>
</template>

<script>
/* eslint-disable */
import TopNav from '@/components/TopNav.vue'
import BottomFooter from '@/components/BottomFooter.vue'
import { useRoute, useRouter } from 'vue-router'
import { useSetting } from '@/store/Setting'
import { useMobile } from "@/store/Mobile"
import { useLanguage } from '@/store/Language'
import { useWeapon } from '@/store/Weapon'
import { storeToRefs } from 'pinia'

export default {
  name: 'App',
  components: {
    TopNav,
    BottomFooter,
  },
  setup() {
    const { language } = storeToRefs(useLanguage())
    const { background } = storeToRefs(useSetting())
    if (useRoute().query.lang != undefined) {
      language.value = Math.max(0, ['en', 'kr', 'jp', 'zh'].indexOf(useRoute().query.lang))
    }
    
    const { 
      equipType,
      equipSet,
      equipRarity,
      equipSearch
    } = storeToRefs(useWeapon())
    
    if (useRoute().query.equipSet != undefined) {
      console.log("equipSet: ", useRoute().query.equipSet)
      equipSet.value = useRoute().query.equipSet.replace("'", "")
      equipType.value = "All"
      equipRarity.value = 0
    }

    if (useRoute().query.equipSearch != undefined) {
      console.log("equipSearch: ", useRoute().query.equipSearch)
      equipSearch.value = useRoute().query.equipSearch.replace(".png", "")
      console.log(equipSearch.value)
      equipType.value = "All"
      equipRarity.value = 0
    }

    const { 
      isMobile,
      screenTop,
      touchstartX,
      touchstartY,
      touchendX,
      touchendY
    } = storeToRefs(useMobile())

    document.addEventListener('touchstart', function (event) {
        screenTop.value = event.view.scrollY
        touchstartX.value = event.changedTouches[0].screenX
        touchstartY.value = event.changedTouches[0].screenY
    }, false);

    document.addEventListener('touchend', function (event) {
        touchendX.value = event.changedTouches[0].screenX
        touchendY.value = event.changedTouches[0].screenY
        useMobile().swipe()
    }, false);

    return {
      isMobile,
      background
    }
  }
}
</script>

<style>
body {
  font-family: sans-serif;
}
#bg-img {
  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transition: background-image 1.5s linear;
}
</style>
