<template>
    <div class="row justify-content-center">
        <div class="col-md-auto offset-md-auto col-11">
            <table class="table table-light rounded rounded-4 overflow-hidden" v-if="isMobile">
                <thead>
                    <tr>
                        <th class="p-0">
                            <button class="btn btn-light w-100" id="filterBtn4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseElement,#collapseClass,#collapseSortBtn" aria-expanded="false" aria-controls="collapseSearchName">
                                <i class="bi bi-funnel-fill"></i>
                            </button>
                            <button class="btn btn-light w-100" id="filterBtn5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSearchName,#collapseSearchSkill" aria-expanded="false" aria-controls="collapseSearchName" style="display:none;">
                                <i class="bi bi-funnel-fill"></i>
                            </button>
                            <button class="btn btn-light w-100" id="filterBtn6" type="button" data-bs-toggle="collapse" data-bs-target="#collapseElement,#collapseClass,#collapseSortBtn,#collapseSearchName,#collapseSearchSkill" aria-expanded="false" aria-controls="collapseSearchName" style="display:none;">
                                <i class="bi bi-funnel-fill"></i>
                            </button>
                        </th>
                    </tr>
                    <tr>
                        <td class="p-0">
                            <div class="collapse" id="collapseElement">
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Water.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Fire.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Nature.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Dark.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Light.png`" alt=""></button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">
                            <div class="collapse" id="collapseClass">
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Guardian.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Warrior.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Assassin.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Ranger.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Mage.png`" alt=""></button>
                                <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Sage.png`" alt=""></button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">
                            <div class="collapse" id="collapseSortBtn">
                                <div class="input-group">
                                    <button class="btn btn-outline-secondary px-2 fs-4 w-50" type="button" @click="clearFilter">
                                        <i class="bi bi-arrow-clockwise"></i>
                                    </button>
                                    <button class="btn btn-outline-secondary px-2 fs-4 w-25" type="button" @click="sortFilter">
                                        <i class="bi bi-sort-down"></i>
                                    </button>
                                    <span class="input-group-text w-25">{{ sortValue }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">
                            <div class="collapse" id="collapseSearchName">
                                <div class="input-group">
                                    <input type="text" aria-label="search" class="form-control" placeholder="Search Name" 
                                    style="background: transparent; font-weight: bold;"
                                    v-model="searchName">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-0">
                            <div class="collapse" id="collapseSearchSkill">
                                <div class="input-group">
                                    <input type="text" aria-label="search" class="form-control" placeholder="Search Skill & Traits" 
                                    style="background: transparent; font-weight: bold;"
                                    v-model="searchSkill">
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
            </table>
            <table class="table table-light table-hover rounded rounded-4 overflow-hidden"  v-else>
                <thead>
                    <tr>
                        <th colspan="6">
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Water.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Fire.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Nature.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Dark.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Attribute_Light.png`" alt=""></button>
                        </th>
                        <th colspan="7">
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Guardian.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Warrior.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Assassin.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Ranger.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Mage.png`" alt=""></button>
                            <button type="button" class="btn btn-outline-secondary btn-filter me-1 p-1" @click="filter"><img :src="`img/UI_Icon_Class_Sage.png`" alt=""></button>
                        </th>
                        <th colspan="4">
                            <div class="input-group">
                                <input type="text" aria-label="search" class="form-control" placeholder="Search Name" 
                                style="background: transparent; font-weight: bold;"
                                v-model="searchName">
                            </div>
                        </th>
                        <th colspan="6">
                            <div class="input-group">
                                <input type="text" aria-label="search" class="form-control" placeholder="Search Skill & Traits" 
                                style="background: transparent; font-weight: bold;"
                                v-model="searchSkill">
                            </div>
                        </th>
                        <th colspan="4">
                            <div class="input-group">
                                <button class="btn btn-outline-secondary w-25 p-0 fs-4" type="button" @click="sortFilter">
                                    <i class="bi bi-sort-down"></i>
                                </button>
                                <span class="input-group-text w-75">{{ sortValue }}</span>
                            </div>
                        </th>
                        <th colspan="2">
                            <button class="btn w-100 p-0 fs-4" type="button" @click="clearFilter">
                                <i class="bi bi-arrow-clockwise"></i>
                            </button>
                        </th>
                    </tr>
                </thead>
            </table>
            <div class="container row mx-0">
                    <div class="col-4 col-md-2 p-0 mb-1" v-for="unit in unitsFilter" :key="unit.Name[language]">
                        <router-link 
                            @click="initialUnit(unit.Cname)"
                            :to="{ 'name': 'heroName', 'params': { heroName: unit.Name[0] }}"
                            >
                            <div class="card text-center h-100">
                                <img :src="`img/UI_Card/${unit.Img_M}`" alt="">
                                <div class="card-body p-2">
                                    <p class="card-text">{{ unit.Name[language] }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { ref, watch } from 'vue'
import { computed } from '@vue/runtime-core'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/store/Mobile'
import { useLanguage } from '@/store/Language'
import { useInitUnit } from '@/store/InitUnit'
import Characters from '@/assets/json/Characters.json'

export default {
    name: 'UnitMenu',
    setup() {
        const { isMobile } = storeToRefs(useMobile())
        const { language, text } = storeToRefs(useLanguage())

        const {
          unit,
          searchName,
          searchSkill,
          elementBtns,
          classBtns,
          elementList,
          classList,
          sortList,
        } = storeToRefs(useInitUnit())

        $(document).ready(function() {
            $(this).on("click", ".setBtn", function() {
                $(this).children().toggle();
            });
            $(this).on("click", "#filterBtn4", function() {
                $(this).toggle();
                $(this).siblings("#filterBtn5").toggle();
            });
            $(this).on("click", "#filterBtn5", function() {
                $(this).toggle();
                $(this).siblings("#filterBtn6").toggle();
            });
            $(this).on("click", "#filterBtn6", function() {
                $(this).toggle();
                $(this).siblings("#filterBtn4").toggle();
            });
        });
        elementBtns.value = [false, false, false, false, false]
        classBtns.value = [false, false, false, false, false, false]
        searchName.value = ''
        searchSkill.value = ''

        const sortValue = ref('Default')
        const sortFilter = (a, b) => {
            sortValue.value = sortList.value[(sortList.value.indexOf(sortValue.value) + 1) % sortList.value.length]
        }
        const sortCharacters = (a, b) => {
            if (sortValue.value == 'ATK') {
                return b.ATK - a.ATK
            }else if (sortValue.value == 'DEF') {
                return b.DEF - a.DEF
            }else if (sortValue.value == 'HP') {
                return b.HP - a.HP
            }else if (sortValue.value == 'Name') {
                return (a.Name[language.value] < b.Name[language.value]) ? -1 : (a.Name[language.value] > b.Name[language.value]) ? 1 : 0
            }else if (sortValue.value == 'Default') {
                return (a.Cname > b.Cname) ? -1 : (a.Cname < b.Cname) ? 1 : 0
            }
        }
        const unitsFilter = computed(() => {
            return Characters
                .sort((a, b) =>{ return sortCharacters(a, b)})
                .filter((unit) => {
                var _element = (elementBtns.value[elementList.value.indexOf(unit.Element[0])]) || (!elementBtns.value.includes(true))
                var _class = (classBtns.value[classList.value.indexOf(unit.Class[0])]) || (!classBtns.value.includes(true))
                var _name = unit.Name[language.value].toLowerCase().includes(searchName.value.toLowerCase())
                if (unit.ActiveDescription.length > 0 && unit.PassiveDescription.length > 0) {
                    var _active = unit.ActiveDescription[language.value][0].toLowerCase().includes(searchSkill.value.toLowerCase())
                    var _passive = unit.PassiveDescription[language.value][0].toLowerCase().includes(searchSkill.value.toLowerCase())
                    var _traits = false
                    unit.TraitsDescription.forEach((item) => {
                        _traits = _traits || item[language.value].toLowerCase().includes(searchSkill.value.toLowerCase())
                    })
                }else {
                    var _active = unit.Name[language.value].toLowerCase().includes(searchSkill.value.toLowerCase())
                    var _passive = unit.Name[language.value].toLowerCase().includes(searchSkill.value.toLowerCase())
                    var _traits = unit.Name[language.value].toLowerCase().includes(searchSkill.value.toLowerCase())
                }
                return (_element && _class) && _name && (_active || _passive || _traits) && sortValue
                
            })
        })

        const initialUnit = (Cname) => {
          // 選擇角色
          $("#ui-card").carousel({   
              pause: true,
              interval: false
          }).carousel(0);
          $("#spine-SD").carousel({   
              pause: true,
              interval: false
          }).carousel(0);
          useInitUnit().initialUnit(Cname)
        }

        const filter = (e) => {
            elementBtns.value.forEach((item, index, array) => {
                if (e.target.closest('button').children[0].attributes["src"].value.indexOf(elementList.value[index]) >= 0) {
                    array[index] = !array[index]
                }
            })
            classBtns.value.forEach((item, index, array) => {
                if (e.target.closest('button').children[0].attributes["src"].value.indexOf(classList.value[index]) >= 0) {
                    array[index] = !array[index]
                }
            })
            e.target.closest('.btn').classList.toggle("active")
        }
        const clearFilter = () => {
            elementBtns.value = [false, false, false, false, false]
            classBtns.value = [false, false, false, false, false, false]
            searchName.value = ''
            searchSkill.value = ''
            $('.btn-filter').removeClass("active")
        }

        return {
            isMobile,
            language,
            text,
            filter,
            clearFilter,
            sortFilter,
            searchName,
            searchSkill,
            sortValue,
            unitsFilter,
            initialUnit
        }
    }
}
</script>

<style scoped>
table {
    table-layout:fixed;
    text-align: center;
}
img {
    width: 100%;
    opacity: 1;
}
/* .menuHero {
    width: 95%;
    height: 90%;
    object-fit: cover;
    object-position: 0% 0%;
} */
.btn-filter {
    width: 2.5em;
    border: 0;
}
.card-text {
    color: black; 
    text-decoration: none;
}
a {
    text-decoration: none;
}
@media screen and (max-width: 768px) {
}
</style>
