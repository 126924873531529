import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:heroName',
    name: 'heroName',
    component: () => import(/* webpackChunkName: "heroName" */ '../views/UnitView.vue')
  },
  {
    path: '/enemies',
    name: 'enemies',
    component: () => import(/* webpackChunkName: "enemies" */ '../views/EnemiesView.vue')
  },
  {
    path: '/haven',
    name: 'haven',
    component: () => import(/* webpackChunkName: "haven" */ '../views/HavenView.vue')
  },
  {
    path: '/calc',
    name: 'calc',
    component: () => import(/* webpackChunkName: "calc" */ '../views/CalcView.vue')
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import(/* webpackChunkName: "equipment" */ '../views/EquipmentView.vue')
  },
  {
    path: '/story',
    name: 'story',
    component: () => import(/* webpackChunkName: "story" */ '../views/StoryView.vue'),
  },
  {
    path: '/abyss',
    name: 'abyss',
    component: () => import(/* webpackChunkName: "abyss" */ '../views/AbyssView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
