/* eslint-disable */
import { defineStore, storeToRefs } from "pinia"
import Characters from '@/assets/json/Characters.json'
import Enemies from '@/assets/json/Enemies.json'

export const useInitUnit = defineStore('InitUnit', {
    state: () => {
        return {
            classTypeList:['Guardian', 'Mage', 'Sage', 'Assassin', 'Ranger', 'Warrior'],
            elementTypeList:['Nature', 'Water', 'Fire', 'Light', 'Dark'],
            damageTypeList:['Physical', 'Magic'],
            searchFilter:'',
            classFilter:'',
            elementFilter:'',
            elementBtns:[false, false, false, false, false],
            classBtns:[false, false, false, false, false, false],
            elementList:['Water', 'Fire', 'Nature', 'Dark', 'Light'],
            classList:['Guardian', 'Warrior', 'Assassin', 'Ranger', 'Mage', 'Sage'],
            sortList:['Default', 'Name', 'ATK', 'DEF', 'HP'],
            searchName:'',
            searchSkill:'',
            unit:[],
        }
    },
    actions: {
        initialUnit(item) {
            if (item != undefined) {
                this.unit = Characters[Characters.findIndex(obj => obj.Cname === item)]
                if (this.unit == undefined){
                    this.unit = Enemies[Enemies.findIndex(obj => obj.Cname === item)]
                }
                console.log(this.unit)
            }
        },
        clearSearch() {
            this.searchFilter = ''
        },
    },
})