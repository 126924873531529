/* eslint-disable */
import { defineStore } from "pinia"


export const useSetting = defineStore('Setting', {
    state: () => {
        return {
            background:'',
            music:'music/music_haven.wav',
            counter: 0,
        }
    },
    
})