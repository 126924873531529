/* eslint-disable */
import { defineStore } from "pinia"
import UI_language from '@/assets/json/Localization/LanguageSource_UI.json'
import { useWeapon } from '@/store/Weapon'

export const useLanguage = defineStore('language', {
    state: () => {
        return {
            languages:["English", "한국어", "日本", "中文"],
            language:0,
            text:{},
        }
    },
    actions: {
        changeLanguage() {
            this.text.language   = this.languages[this.language]
            this.text.drop       = ["Drop", "드랍템", "", "掉落"][this.language]
            this.text.star       = ["Star", "성", "", "星星"][this.language]
            this.text.enemy       = ["Enemy", "몬스터", "", "怪物"][this.language]
            this.text.skill       = ["Skill", "스킬", "スキル", "技能"][this.language]
            this.text.res       = ["RES", "저항력", "耐性力", "抵抗"][this.language]
            this.text.recSet       = ["Rec. Set", "권장 세트", "おすすめセット", "推薦套裝"][this.language]
            this.text.weapon       = ["Weapon", "무기", "武器", "武器"][this.language]
            this.text.armor       = ["Armor", "방어구", "防具", "防具"][this.language]
            this.text.accessory       = ["Accessory", "장신구", "装身具", "飾品"][this.language]
            this.text.mythic       = ["Mythic", "신화", "ミシック", "神話"][this.language]
            this.text.legendary       = ["Legendary", "전설", "レジェンド", "傳說"][this.language]
            this.text.epic       = ["Epic", "유니크", "ユニーク", "史詩"][this.language]
            this.text.rare       = ["Rare", "레어", "レア", "稀有"][this.language]
            this.text.story      = ["Story", "스토리", "ストーリー", "故事"][this.language]
            this.text.evaluation = ["Evaluation", "우아함 평가", "優雅さ評価", "優雅評價"][this.language]
            this.text.equipment  = UI_language.mSource.mTerms.filter(item => item.Term === 'UI/Inventory_Button_Equipment')[0].Languages[this.language]
            this.text.hero       = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Hero')[0].Languages[this.language]
            this.text.haven      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Haven')[0].Languages[this.language]
            this.text.search     = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Search')[0].Languages[this.language]
            this.text.level      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Level')[0].Languages[this.language]
            this.text.classList  = UI_language.mSource.mTerms.filter(item => item.Term.includes("_NoUI")).map(obj => { return obj.Languages[this.language] })
            this.text.elementList= UI_language.mSource.mTerms.filter(item => item.Term.includes("Attributes/Element_")).map(obj => { return obj.Languages[this.language].split('>')[1] })
            this.text.class      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Class')[0].Languages[this.language]
            this.text.element    = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Element')[0].Languages[this.language]
            this.text.dmgType    = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'DMG Type')[0].Languages[this.language]
            this.text.level      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Level')[0].Languages[this.language]
            this.text.atk        = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'ATK')[0].Languages[this.language]
            this.text.def        = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'DEF')[0].Languages[this.language]
            this.text.hp         = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'HP')[0].Languages[this.language]
            this.text.energyGain = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Energy Gain')[0].Languages[this.language]
            this.text.aspd       = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'ATK SPD')[0].Languages[this.language]
            this.text.range      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Range')[0].Languages[this.language]
            this.text.mspd       = ["Movement Speed", "이동 속도", "", "移動速度"][this.language]
            this.text.crit       = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Crit Rate')[0].Languages[this.language]
            this.text.cdmg       = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Crit DMG')[0].Languages[this.language]
            this.text.eva        = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Evasion')[0].Languages[this.language]
            this.text.acc        = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Accuracy')[0].Languages[this.language]
            this.text.pres       = UI_language.mSource.mTerms.filter(item => item.Term === 'Stats/physicalResistance_StatName')[0].Languages[this.language]
            this.text.mres       = UI_language.mSource.mTerms.filter(item => item.Term === 'Stats/magicResistance_StatName')[0].Languages[this.language]
            this.text.resilience = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Resilience')[0].Languages[this.language]
            this.text.lifeSteal  = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Lifesteal')[0].Languages[this.language]
            this.text.healBonus  = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Heal Bonus')[0].Languages[this.language]
            this.text.background = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Background')[0].Languages[this.language].replace("<size=40>", "")
            this.text.ability    = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Abilities')[0].Languages[this.language]
            this.text.active     = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Active')[0].Languages[this.language]
            this.text.passive    = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Passive')[0].Languages[this.language]
            this.text.trait      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Traits')[0].Languages[this.language]
            this.text.pen      = UI_language.mSource.mTerms.filter(item => item.Languages[0] === 'Penetration')[0].Languages[this.language]
            this.text.PieceBonus = UI_language.mSource.mTerms.filter(item => item.Term.includes('PieceBonus'))[0].Languages[this.language]
            this.text.cd         = UI_language.mSource.mTerms.filter(item => item.Languages[0].includes("s CD"))[0].Languages[this.language].split('}')[1]
        },
    },
})