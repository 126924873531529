/* eslint-disable */
import { defineStore } from "pinia"
import UI_language from '@/assets/json/Localization/LanguageSource_UI.json'
import SetBonus_language from '@/assets/json/Localization/LanguageSource_SetBonus.json'
import Inventory_language from '@/assets/json/Localization/LanguageSource_Inventory.json'
import Equipments from '@/assets/json/Equipment.json'
import setBonus from '@/assets/json/setBonus.json'

export const useWeapon = defineStore('weapon', {
    state: () => {
        return {
            Equipments:Equipments,
            setBonus:setBonus,
            equipType:"Weapon",
            equipName:"",
            equipSet:"All",
            equipSearch:"",
            equipRarity:5,
            level:70,
            star:5,
        }
    },
    actions: {
        setLevel() {
            this.Equipments.forEach((item) => {
                if (item.type == "Weapon"){
                    item.finalATK =  Math.floor(item.baseATK * (1 + (0.15 * (this.level - 1))))
                }
                if (item.type == "Armor" || item.type == "Accessory"){
                    if (item.primaryStatValue1) {
                        if (item.primaryStatValue1 > 10) {
                            item.finalValue1 = Math.floor(item.primaryStatValue1 * (1 + (0.15 * (this.level - 1))))
                        }else {
                            item.finalValue1 = (Math.round(item.primaryStatValue1 * (10 + (1.5 * (this.level - 1))))) / 10
                        }
                    }
                    if (item.primaryStatValue2) {
                        if (item.primaryStatValue2 > 10) {
                            item.finalValue2 = Math.floor(item.primaryStatValue2 * (1 + (0.15 * (this.level - 1))))
                        }else {
                            item.finalValue2 = (Math.round(item.primaryStatValue2 * (10 + (1.5 * (this.level - 1))))) / 10
                        }
                    }
                }

            })
        },
        changeLanguage(nV) {
            console.log(123)
            this.Equipments.forEach((item) => {

                console.log(item.imgName)

                item.name = Inventory_language.mSource.mTerms.filter((el) => {
                    return el.Term.toLowerCase() == `Equipment/${item.fileName}_Name`.toLowerCase()
                })[0].Languages[nV]

                item.description = Inventory_language.mSource.mTerms.filter((el) => {
                    return el.Term.toLowerCase() == `Equipment/${item.fileName}_Description`.toLowerCase()
                })[0].Languages[nV]

                if (item.setName) {
                    item.SetBonusName = SetBonus_language.mSource.mTerms.filter((el) => {
                        return el.Term.toLowerCase().includes(`SetBonusName/`.toLowerCase()) && el.Term.toLowerCase().includes(`${item.setName}`.toLowerCase())
                    })[0].Languages[nV]
                    item.SetBonusDescription = SetBonus_language.mSource.mTerms.filter((el) => {
                        return el.Term.toLowerCase().includes(`SetBonusDescription/`.toLowerCase()) && el.Term.toLowerCase().includes(`${item.setName}`.toLowerCase())
                    })
                }

                if (item.stat1) {
                    item.stat1.text = UI_language.mSource.mTerms.filter(obj => obj.Languages[0] === item.stat1.en)[0].Languages[nV]
                }

                if (item.stat2) {
                    item.stat2.text = UI_language.mSource.mTerms.filter(obj => obj.Languages[0] === item.stat2.en)[0].Languages[nV]
                }
                
                if (item.stat3) {
                    item.stat3.text = UI_language.mSource.mTerms.filter(obj => obj.Languages[0] === item.stat3.en)[0].Languages[nV]
                }
                
                if (item.primaryStat1) {
                    item.primaryStat1 = UI_language.mSource.mTerms.filter(obj => obj.Languages[0] === item.en1)[0].Languages[nV]
                }
                
                if (item.primaryStat2) {
                    item.primaryStat2 = UI_language.mSource.mTerms.filter(obj => obj.Languages[0] === item.en2)[0].Languages[nV]
                }


            })
        }
    },
})