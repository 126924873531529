import { defineStore } from "pinia"

export const useMobile = defineStore('mobile', {
    state: () => {
        return {
            isMobile:screen.width <= 768,
            isTopNav:true,
            isSideNav:false,
            screenTop:0,
            touchstartX:0,
            touchstartY:0,
            touchendX:0,
            touchendY:0,
        }
    },
    actions: {
        swipe() {
            if ((this.touchendX < this.touchstartX) && (Math.abs(this.touchendX - this.touchstartX) > Math.abs(this.touchendY - this.touchstartY))) {
                this.isSideNav = false
            }
    
            if ((this.touchendX > this.touchstartX) && (Math.abs(this.touchendX - this.touchstartX) > Math.abs(this.touchendY - this.touchstartY))) {
                this.isSideNav = true
            }
    
            // if ((this.touchendY < this.touchstartY) && (Math.abs(this.touchendX - this.touchstartX) < Math.abs(this.touchendY - this.touchstartY))) {
            //     this.closeNavbar()
            //     this.isTopNav = false
            // }
    
            // if ((this.touchendY > this.touchstartY) && (Math.abs(this.touchendX - this.touchstartX) < Math.abs(this.touchendY - this.touchstartY))) {
            //     this.isTopNav = true
            // }

            // if ((this.touchendY > this.touchstartY) && (Math.abs(this.touchendX - this.touchstartX) < Math.abs(this.touchendY - this.touchstartY)) && (this.screenTop === 0)) {
            //     this.openNavbar()
            // }
    
            // if (this.touchendY === this.touchstartY) {
            //     this.closeNavbar()
            // }
        },
        closeNavbar() {
            if(document.getElementById("mainNavbar").classList.contains("show")) {
                document.getElementById("mainNavbarToggler").click()
            }
        },
        openNavbar() {
            if(!document.getElementById("mainNavbar").classList.contains("show")) {
                document.getElementById("mainNavbarToggler").click()
            }
        },
        toggleSideNav() {
            this.isSideNav = !this.isSideNav
        },
    }
})