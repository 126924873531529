<template>
    <footer class="p-4 p-6">
        <hr class="my-6 mx-sm-auto">
        <div class="flex items-center justify-between">
            <div>
                <span class="text-sm text-center">any suggestion or issue report please @Dieark in 
                    <a class="btn btn-outline-secondary border-0" href="https://discord.gg/2wh4Yr5dNg" target="_blank">
                        <i class="bi bi-discord"></i>
                    </a>
                </span>
                <br>
                <span class="text-sm text-center"> Images and data: © Gaudium Inc. &amp; EIGHT STUDIO Inc.</span>
                <br>
                <span class="text-sm text-center">made by <i>Dieark(幽黯魂殤)</i></span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'BottomFooter'
}
</script>

<style scoped>
footer {
    /* position: absolute; */
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    /* margin-top: calc(60vw - 100vh); */
    background-color: rgb(255, 255, 255, 0.4);
}
</style>
  