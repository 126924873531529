<template>
    <div class="navbar navbar-expand-lg navbar-light" id="mainNav" 
    style="width: 100%; background-image: url(img/bg1.png); background-attachment: fixed; z-index: -10;">
    </div>
    <nav class="navbar navbar-expand-lg navbar-light" style="z-index: 10;"
    v-if="isTopNav">
        <div class="container px-3 px-lg-5 py-2">
            <!-- <button type="button" class="btn fs-1" @click="toggleSideNav" v-if="isMobile">
                <i class="bi bi-arrow-bar-left" v-if="isSideNav"></i>
                <i class="bi bi-arrow-bar-right" v-else></i>
            </button> -->
            <router-link to="/" v-if="isMobile">
                <button type="button" class="btn fs-1">
                    <i class="bi bi-house"></i>
                </button>
            </router-link>
            <router-link to="/" class="navbar-brand mx-0"><img src="img/logo_black.png" alt="Grimlight" style="width: 100%;"></router-link>
            <button class="navbar-toggler" id="mainNavbarToggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mainNavbar">
                <ul class="navbar-nav ms-auto py-4 py-lg-0">
                    <router-link @click="closeNavbar" to="/" class="nav-link px-lg-3 py-3 py-lg-4"><li class="nav-item">{{ text.hero }}</li></router-link>
                    <router-link @click="closeNavbar" to="/enemies" class="nav-link px-lg-3 py-3 py-lg-4"><li class="nav-item">{{ text.enemy }}</li></router-link>
                    <router-link @click="closeNavbar" to="/calc" class="nav-link px-lg-3 py-3 py-lg-4"><li class="nav-item">Calc</li></router-link>
                    <router-link @click="closeNavbar" to="/haven" class="nav-link px-lg-3 py-3 py-lg-4"><li class="nav-item">{{ text.haven }}</li></router-link>
                    <router-link @click="closeNavbar" to="/equipment" class="nav-link px-lg-3 py-3 py-lg-4"><li class="nav-item">{{ text.equipment }}</li></router-link>
                    <router-link @click="closeNavbar" to="/story" class="nav-link px-lg-3 py-3 py-lg-4"><li class="nav-item" v-if="!isMobile">{{ text.story }}</li></router-link>
                    <li class="nav-item dropdown border-right">
                        <a class="nav-link dropdown-toggle px-lg-3 py-3 py-lg-4" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Language
                        </a>
                        <ul class="dropdown-menu" @click="closeNavbar">
                            <li><a class="dropdown-item" href="#" @click="changeLanguage(0)">English</a></li>
                            <li><a class="dropdown-item" href="#" @click="changeLanguage(1)">한국어</a></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><a class="dropdown-item disabled">Not ready yet</a></li>
                            <li><a class="dropdown-item" href="#" @click="changeLanguage(2)">日本</a></li>
                            <li><a class="dropdown-item" href="#" @click="changeLanguage(2)">中文</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
  
<script>
/* eslint-disable */
import { ref } from 'vue'
import { useMobile } from "@/store/Mobile"
import { useLanguage } from '@/store/Language'
import { storeToRefs } from 'pinia'
export default {
    name: 'TopNav',
    setup() {
        console.log("top")
        const { isMobile, isTopNav } = storeToRefs(useMobile())
        const { isSideNav } = storeToRefs(useMobile())
        const { language, text } = storeToRefs(useLanguage())

        const toggleSideNav = () => {
            useMobile().toggleSideNav()
        }

        const closeNavbar = () => {
            useMobile().closeNavbar()
        }

        const changeLanguage = (lang) => {
            language.value = lang
        }

        return {
            isMobile,
            isTopNav,
            isSideNav,
            text,
            toggleSideNav,
            closeNavbar,
            changeLanguage,
        }
    }
}
</script>

<style scoped>
.navbar-brand {
    width: 15%;
}
.nav-link {
    color: black;
}

@media screen and (max-width: 768px) {
    .navbar-brand {
        width: 25%;
    }
    #mainNav {
        position:fixed;
        top: 0;
        z-index: -20;
    }
}
</style>