<template>
    <div class="container">
      <div class="row">
        <UnitMenu/>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import UnitMenu from '@/components/UnitMenu.vue'
import { useLanguage } from '@/store/Language'
import { useInitUnit } from "@/store/InitUnit"
import { storeToRefs } from 'pinia'
import Characters from '@/assets/json/Characters.json'

export default {
  name: 'HomeView',
  components: {
      UnitMenu,
  },
  setup() {
    console.log(useRoute().params.heroName)
    const { language } = storeToRefs(useLanguage())

    watch(language, () => {
        useLanguage().changeLanguage()
    })

    const { unit } = storeToRefs(useInitUnit())
    unit.value = useRoute().params.heroName === undefined
      ? Characters[11] // Alice
      : Characters[Characters.findIndex(obj => 
          (obj.Name[0].toLowerCase() === useRoute().params.heroName.toString().toLowerCase()) || 
          (obj.Name[0].replace(" ", "").toLowerCase() === useRoute().params.heroName.toString().toLowerCase())
        )]
    if (unit.value === undefined) {
      unit.value = Characters[11]
      useLanguage().changeLanguage()
      useRouter().push({ path:'/' })
    } else {
      console.log(unit.value)
      useLanguage().changeLanguage()
    }
  }
}
</script>
