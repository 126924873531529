import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

const pinia = createPinia()
const vueApp = createApp(App)
vueApp.use(router).use(pinia)

router.isReady().then(() => vueApp.mount('#app'))
